import Layout from "@/views/Layout/index.vue";
let addRoutes = [];
export default addRoutes = [
  {
    path: "/chainHome",
    name: "ChainHome",
    redirect: "/chainHomeDetail",
    meta: {
      title: "首页",
      icon: "el-icon-s-home"
    },
    component: "layout",
    children: [
      {
        path: "/chainHomeDetail",
        component: "/otcCommon/homePage/index.vue",
        // hidden: true,
        meta: {
          title: "首页"
        }
      }
    ]
  },
  {
    path: "/chainLargeScreen",
    name: "chainLargeScreen",
    redirect: "/chainLargeScreenDetail",
    meta: {
      title: "大数据",
      icon: "el-icon-s-marketing"
    },
    component: "layout",
    children: [
      {
        path: "/chainLargeScreenDetail",
        component: "/otcCommon/largeScreen/index.vue",
        // hidden: true,
        meta: {
          title: "大数据"
        }
      }
    ]
  },
  {
    path: "/patientManage",
    name: "PatientManage",
    redirect: "/patientManageDetail",
    meta: {
      title: "患者管理",
      icon: "el-icon-user-solid"
    },
    component: "layout",
    children: [
      {
        path: "/patientManageDetail",
        component: "/otcCommon/patientManage/index.vue",
        // hidden: true,
        meta: {
          title: "患者管理"
        }
      },
      {
        path: "/patientDetails",
        component: "/otcCommon/patientManage/patientDetails.vue",
        hidden: true,
        meta: {
          title: "患者详情",
          guidePath: true,
          jumpPath: "/patientManageDetail"
        }
      }
    ]
  },
  {
    path: "/chainMsgManage",
    name: "ChainMsgManage",
    redirect: "/chainMsgManageDetail",
    meta: {
      title: "消息管理",
      icon: "el-icon-s-comment"
    },
    component: "layout",
    children: [
      {
        path: "/chainMsgManageDetail",
        component: "/otcCommon/msgMange/index.vue",
        // hidden: true,
        meta: {
          title: "消息管理"
        }
      },
      {
        path: "/pushRecord",
        component: "/otcCommon/msgMange/pushRecord.vue",
        hidden: true,
        meta: {
          title: "推送记录",
          guidePath: true,
          jumpPath: "/chainMsgManageDetail"
        }
      }
    ]
  },
  {
    path: "/dataManage",
    // hidden: false,
    name: "DataManage",
    redirect: "/sugarDataManage",
    meta: {
      title: "数据管理",
      icon: "el-icon-s-platform"
    },
    component: "layout",
    children: [
      {
        path: "/sugarDataManage",
        component: "/otcCommon/dataManage/index.vue",
        // hidden: true,
        meta: {
          title: "血糖数据",
          type: "sugar"
        }
      },

      {
        path: "/pressureDataManage",
        component: "/otcCommon/dataManage/index.vue",
        // hidden: true,
        meta: {
          title: "血压数据",
          type: "pressure"
        }
      },
      {
        path: "/fatDataManage",
        component: "/otcCommon/dataManage/index.vue",
        // hidden: true,
        meta: {
          title: "血脂数据",
          type: "fat"
        }
      },
      {
        path: "/heartDataManage",
        component: "/otcCommon/dataManage/index.vue",
        // hidden: true,
        meta: {
          title: "心电数据",
          type: "heart"
        }
      },
      {
        path: "/ketoneDataManage",
        component: "/otcCommon/dataManage/index.vue",
        // hidden: true,
        meta: {
          title: "血酮数据",
          type: "ketone"
        }
      },
      {
        path: "/uricAcidDataManage",
        component: "/otcCommon/dataManage/index.vue",
        // hidden: true,
        meta: {
          title: "尿酸数据",
          type: "uricAcid"
        }
      }
    ]
  },
  {
    path: "/knowledgeBase",
    // hidden: false,
    name: "KnowledgeBase",
    redirect: "/knowledgeBaseDetail",
    meta: {
      title: "知识库管理",
      icon: "el-icon-s-opportunity"
      // icon: "https://img-home.csdnimg.cn/images/20220107104621.png"
    },
    component: "layout",
    children: [
      {
        path: "/knowledgeBaseDetail",
        component: "/otcChain/knowledgeBase/index.vue",
        // hidden: true,
        meta: {
          title: "知识库管理"
        }
      }
    ]
  },
  {
    path: "/chronicDisease",
    // hidden: false,
    name: "chronicDisease",
    redirect: "/chronicDiseaseDetail",
    meta: {
      title: "慢病管理",
      icon: "el-icon-s-management"
    },
    component: "layout",
    children: [
      {
        path: "/chronicDiseaseDetail",
        component: "/otcChain/chronicDisease/index.vue",
        // hidden: true,
        meta: {
          title: "慢病管理"
        }
      }
    ]
  },
  {
    path: "/regionManage",
    // hidden: false,
    name: "RegionManage",
    redirect: "/regionManageDetail",
    meta: {
      title: "大区管理",
      icon: "el-icon-menu"
    },
    component: "layout",
    children: [
      {
        path: "/regionManageDetail",
        component: "/otcChain/regionMange/index.vue",
        // hidden: true,
        meta: {
          title: "大区管理"
        }
      }
    ]
  },
  {
    path: "/areaMange",
    // hidden: false,
    name: "AreaManage",
    redirect: "/areaManageDetail",
    meta: {
      title: "片区管理",
      icon: "el-icon-s-grid"
    },
    component: "layout",
    children: [
      {
        path: "/areaManageDetail",
        component: "/otcCommon/areaMange/index.vue",
        // hidden: true,
        meta: {
          title: "片区管理"
        }
      }
    ]
  },
  {
    path: "/pharmacyMange",
    // hidden: false,
    name: "PharmacyMange",
    redirect: "/pharmacyMangeDetail",
    meta: {
      title: "药店管理",
      icon: "el-icon-s-shop"
    },
    component: "layout",
    children: [
      {
        path: "/pharmacyMangeDetail",
        component: "/otcCommon/pharmacyMange/index.vue",
        // hidden: true,
        meta: {
          title: "药店管理"
        }
      }
    ]
  },
  {
    path: "/hotSellList",
    // hidden: false,
    name: "HotSellList",
    redirect: "/hotSellListDetail",
    meta: {
      title: "热销榜",
      icon: "el-icon-s-flag"
    },
    component: "layout",
    children: [
      {
        name: "HotSellListDetail",
        path: "/hotSellListDetail",
        component: "/otcChain/hotSellList/index.vue",
        // hidden: true,
        meta: {
          title: "热销榜"
        }
      },
      {
        name: "HotSellGoodsDetail",
        path: "/hotSellGoodsDetail",
        component: "/otcChain/hotSellList/hotDetail.vue",
        hidden: true,
        meta: {
          title: "热销商品"
        }
      }
    ]
  },
  {
    path: "/statistics",
    // hidden: false,
    name: "Statistics",
    redirect: "/statisticsSugar",
    meta: {
      title: "统计",
      icon: "el-icon-s-data"
      // icon: "https://img-home.csdnimg.cn/images/20220107104621.png"
    },
    component: "layout",
    children: [
      {
        path: "/statisticsSugar",
        component: "/otcChain/chainStatistics/sugar.vue",
        // hidden: true,
        meta: {
          title: "统计-血糖"
        }
      },
      {
        path: "/statisticsPressure",
        component: "/otcChain/chainStatistics/pressure.vue",
        // hidden: true,
        meta: {
          title: "统计-血压"
        }
      }
    ]
  },
  {
    path: "/testStrip",
    // hidden: false,
    name: "TestStrip",
    redirect: "/testStripDetail",
    meta: {
      title: "试条用量统计",
      icon: "el-icon-s-order"
      // icon: "https://img-home.csdnimg.cn/images/20220107104621.png"
    },
    component: "layout",
    children: [
      {
        path: "/testStripDetail",
        component: "/otcCommon/testStrip/index.vue",
        // hidden: true,
        meta: {
          title: "试条用量统计"
        }
      }
    ]
  }
  // {
  //   path: "/testEcg",
  //   // hidden: false,
  //   name: "TestEcg",
  //   redirect: "/testEcgDetail",
  //   meta: {
  //     title: "测试",
  //     icon: "el-icon-s-order"
  //     // icon: "https://img-home.csdnimg.cn/images/20220107104621.png"
  //   },
  //   component: "layout",
  //   children: [
  //     {
  //       path: "/testEcgDetail",
  //       component: "/otcCommon/test/ecg.vue",
  //       // hidden: true,
  //       meta: {
  //         title: "测试"
  //       }
  //     }
  //   ]
  // }
];
