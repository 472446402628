import Layout from "@/views/Layout/index.vue";
let addRoutes = [];
export default addRoutes = [

  {
    path: "/chainMange",
    // hidden: false,
    name: "chainMange",
    redirect: "/chainMangeDetail",
    meta: {
      title: "连锁管理",
    },
    component: "layout",
    children: [
      {
        path: "/chainMangeDetail",
        component: "/otcHealth/chainMange/index.vue",
        // hidden: true,
        meta: {
          title: "连锁管理",
          icon: "el-icon-menu"
        }
      }
    ]
  },
  {
    path: "/sensitive",
    // hidden: false,
    name: "Sensitive",
    redirect: "/sensitiveDetail",
    meta: {
      title: "敏感词管理",
      icon: "el-icon-s-release"
    },
    component: "layout",
    children: [
      {
        path: "/sensitiveDetail",
        component: "/otcHealth/sensitive/index.vue",
        // hidden: true,
        meta: {
          title: "敏感词管理"
        }
      }
    ]
  },
  {
    path: "/article",
    // hidden: false,
    name: "Article",
    redirect: "/articleDetail",
    meta: {
      title: "怡成文章库",
      icon: "el-icon-s-management"
    },
    component: "layout",
    children: [
      {
        path: "/articleDetail",
        component: "/otcHealth/article/index.vue",
        // hidden: true,
        meta: {
          title: "怡成文章库"
        }
      }
    ]
  },
  {
    path: "/equipmentModel",
    // hidden: false,
    name: "EquipmentModel",
    redirect: "/equipmentModelDetail",
    meta: {
      title: "设备型号管理",
      icon: "el-icon-s-order"
    },
    component: "layout",
    children: [
      {
        path: "/equipmentModelDetail",
        component: "/otcHealth/equipmentModel/index.vue",
        // hidden: true,
        meta: {
          title: "设备型号管理"
        }
      }
    ]
  },
  {
    path: "/equipment",
    // hidden: false,
    name: "Equipment",
    redirect: "/undistributed",
    meta: {
      title: "设备管理",
      icon: "el-icon-s-cooperation"
      // icon: "https://img-home.csdnimg.cn/images/20220107104621.png"
    },
    component: "layout",
    children: [
      {
        path: "/undistributed",
        component: "/otcHealth/equipment/undistributed.vue",
        // hidden: true,
        meta: {
          title: "未分配"
        }
      },
      {
        path: "/assigned",
        component: "/otcHealth/equipment/assigned.vue",
        // hidden: true,
        meta: {
          title: "已分配"
        }
      },
    ]
  },
  {
    path: "/userMange",
    // hidden: false,
    name: "UserMange",
    redirect: "/userMangeDetail",
    meta: {
      title: "用户管理",
      icon: "el-icon-user-solid"
    },
    component: "layout",
    children: [
      {
        path: "/userMangeDetail",
        component: "/otcHealth/userMange/index.vue",
        // hidden: true,
        meta: {
          title: "用户管理"
        }
      }
    ]
  },
];
