import Layout from "@/views/Layout/index.vue";
let addRoutes = [];
export default addRoutes = [
  {
    path: "/pharmacyHome",
    name: "PharmacyHome",
    redirect: "/pharmacyHomeDetail",
    meta: {
      title: "首页",
      icon: "el-icon-s-home"
    },
    component: "layout",
    children: [
      {
        path: "/pharmacyHomeDetail",
        component: "/otcCommon/homePage/index.vue",
        // hidden: true,
        meta: {
          title: "首页"
        }
      }
    ]
  },
  {
    path: "/pharmacyLargeScreen",
    name: "PharmacyLargeScreen",
    redirect: "/pharmacyLargeScreenDetail",
    meta: {
      title: "大数据",
      icon: "el-icon-s-marketing"
    },
    component: "layout",
    children: [
      {
        path: "/pharmacyLargeScreenDetail",
        component: "/otcCommon/largeScreen/index.vue",
        // hidden: true,
        meta: {
          title: "大数据"
        }
      }
    ]
  },
  {
    path: "/patientManage",
    name: "PatientManage",
    redirect: "/patientManageDetail",
    meta: {
      title: "患者管理",
      icon: "el-icon-user-solid"
    },
    component: "layout",
    children: [
      {
        path: "/patientManageDetail",
        component: "/otcCommon/patientManage/index.vue",
        // hidden: true,
        meta: {
          title: "患者管理"
        }
      },
      {
        path: "/patientDetails",
        component: "/otcCommon/patientManage/patientDetails.vue",
        hidden: true,
        meta: {
          title: "患者详情",
          guidePath: true,
          jumpPath: "/patientManageDetail"
        }
      }
    ]
  },
  {
    path: "/dataManage",
    // hidden: false,
    name: "DataManage",
    redirect: "/sugarDataManage",
    meta: {
      title: "数据管理",
      icon: "el-icon-s-platform"
    },
    component: "layout",
    children: [
      {
        path: "/sugarDataManage",
        component: "/otcCommon/dataManage/index.vue",
        // hidden: true,
        meta: {
          title: "血糖数据",
          type: "sugar"
        }
      },

      {
        path: "/pressureDataManage",
        component: "/otcCommon/dataManage/index.vue",
        // hidden: true,
        meta: {
          title: "血压数据",
          type: "pressure"
        }
      },
      {
        path: "/fatDataManage",
        component: "/otcCommon/dataManage/index.vue",
        // hidden: true,
        meta: {
          title: "血脂数据",
          type: "fat"
        }
      },
      {
        path: "/heartDataManage",
        component: "/otcCommon/dataManage/index.vue",
        // hidden: true,
        meta: {
          title: "心电数据",
          type: "heart"
        }
      },
      {
        path: "/ketoneDataManage",
        component: "/otcCommon/dataManage/index.vue",
        // hidden: true,
        meta: {
          title: "血酮数据",
          type: "ketone"
        }
      },
      {
        path: "/uricAcidDataManage",
        component: "/otcCommon/dataManage/index.vue",
        // hidden: true,
        meta: {
          title: "尿酸数据",
          type: "uricAcid"
        }
      }
    ]
  },

  {
    path: "/testStrip",
    // hidden: false,
    name: "TestStrip",
    redirect: "/testStripDetail",
    meta: {
      title: "试条用量统计",
      icon: "el-icon-s-order"
      // icon: "https://img-home.csdnimg.cn/images/20220107104621.png"
    },
    component: "layout",
    children: [
      {
        path: "/testStripDetail",
        component: "/otcCommon/testStrip/index.vue",
        // hidden: true,
        meta: {
          title: "试条用量统计"
        }
      }
    ]
  }
];
